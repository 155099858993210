<template>
  <div class="project-collection-con flex flex-wrap" :class="{'project-collection-con-lg': breakpoint < breakpointList.lg}">
    <div class="project-collection" id="projectList" :class="{'project-collection-lg': breakpoint < breakpointList.lg}">
      <div class="project-collection-title" id="projectListTitle">
        <a-dropdown :trigger="['click']">
          <span style="cursor:pointer">
            <span>
              HKATG
            </span>
            <span>
              <CaretDownOutlined />
              <!-- <CaretUpOutlined /> -->
            </span>
          </span>
          <template #overlay>
            <a-menu @click="handleMenuClick">
              <a-menu-item
                v-for="(item, index) in enterpriseList"
                :key="index">
                {{item.title}}
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
      <div class="project-collection-search flex flex-j-c-sb">
        <!-- <a-input placeholder="Basic usage" /> -->
        <div>
          <span>
            {{$t('header.projectList')}}
          </span>
        </div>
        <div>
           <SearchOutlined class="icon" />
           <PlusOutlined class="icon" style="margin-left:6px;" />
        </div>
      </div>
      <div class="project-collection-detail" :class="{'project-collection-detail-lg': breakpoint < breakpointList.lg}">
        <div v-for="(item,idx) in projectList" :key="idx" class="flex project-collection-item" @click="toProjectDetail(item)">
          <div class="project-collection-item-img"><img :src="item.img" style="width:92px;height:72px;" /></div>
          <div class="project-collection-item-info flex flex-j-c-center">
            <div class="project-collection-item-info-title">
              <span>{{item.name}}</span>
              <span
                :class="item.collected ? '' : 'non-f'"
                class="collection-icon">
                <StarOutlined class="non-collect-icon" v-if="!item.collected"/>
                <StarFilled
                  :class="item.collected ? '' : 'non-f'"
                  class="collect-icon"
                  style="color:#FFD000"
                  @click="changeCollection(item)"/>
              </span>
            </div>
            <div class="project-collection-item-info-time flex flex-wrap flex-j-c-sb">
              <div style="margin-right:16px;margin-bottom: 4px;">{{$t('project.projectList.members', {count: item.memberNum})}}</div>
              <div style="margin-right:16px">{{$t('project.projectList.started', {days: item.startDate})}}</div>
              <div>{{$t('project.projectList.lastUpdated', {time: dayjs(item.updatedTime).fromNow()})}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="map" id="mapWrap" :class="{'map-lg': breakpoint < breakpointList.lg}">
      <!-- <div id="map" class="map-content"></div> -->
      <MapComponent
      :positionList="projectList"/>
    </div>
  </div>
</template>

<script >
import { defineComponent, reactive, toRefs, onMounted, watchEffect, ref, nextTick } from 'vue'
import { SearchOutlined, PlusOutlined, StarOutlined, StarFilled, CaretDownOutlined } from '@ant-design/icons-vue'
import { useRouter } from 'vue-router'
import { mapState, useStore } from 'vuex'
// import AMap from 'AMap'
import dayjs from 'dayjs'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import MapComponent from '@/components/MapComponent'
// import qs from 'qs'
// import dayjs from 'dayjs'
// import api from '@/services/api'
// import config from '@/config'
// import { Empty, message } from 'ant-design-vue'

export default defineComponent({
  components: {
    SearchOutlined,
    PlusOutlined,
    StarOutlined,
    StarFilled,
    CaretDownOutlined,
    MapComponent
    // CaretUpOutlined
  },
  computed: {
    ...mapState([
      'breakpoint',
      'breakpointList'
    ])
  },
  setup () {
    const router = useRouter()
    console.log(router)
    const store = useStore()
    dayjs.extend(relativeTime)
    const state = reactive({
      dayjs,
      projectList: [{
        id: '1',
        img: 'projectItem.png',
        name: 'Two Harbour Square',
        memberNum: 28,
        startDate: 72,
        updatedTime: '2021-12-06',
        collected: true,
        position: {
          lng: 114.226031,
          lat: 22.30693
        }
      },
      {
        id: '2',
        img: 'projectItem.png',
        name: 'Metroplaza Tower',
        memberNum: 6,
        startDate: 36,
        updatedTime: '2021-12-02',
        position: {
          lng: 114.20572,
          lat: 22.259999
        }
      },
      {
        id: '2',
        img: 'projectItem.png',
        name: 'Metroplaza Tower',
        memberNum: 6,
        startDate: 36,
        updatedTime: '2021-12-02',
        position: {
          lng: 0,
          lat: 0
        }
      },
      {
        id: '2',
        img: 'projectItem.png',
        name: 'Metroplaza Tower',
        memberNum: 6,
        startDate: 36,
        updatedTime: '2021-12-02',
        position: {
          lng: 0,
          lat: 0
        }
      },
      {
        id: '2',
        img: 'projectItem.png',
        name: 'Metroplaza Tower',
        memberNum: 6,
        startDate: 36,
        updatedTime: '2021-12-02',
        position: {
          lng: 0,
          lat: 0
        }
      },
      {
        id: '2',
        img: 'projectItem.png',
        name: 'Metroplaza Tower',
        memberNum: 6,
        startDate: 36,
        updatedTime: '2021-12-02',
        position: {
          lng: 0,
          lat: 0
        }
      },
      {
        id: '2',
        img: 'projectItem.png',
        name: 'Metroplaza Tower',
        memberNum: 6,
        startDate: 36,
        updatedTime: '2021-12-02',
        position: {
          lng: 0,
          lat: 0
        }
      },
      {
        id: '2',
        img: 'projectItem.png',
        name: 'Metroplaza Tower',
        memberNum: 6,
        startDate: 36,
        updatedTime: '2021-12-02',
        position: {
          lng: 0,
          lat: 0
        }
      },
      {
        id: '2',
        img: 'projectItem.png',
        name: 'Metroplaza Tower',
        memberNum: 6,
        startDate: 36,
        updatedTime: '2021-12-02',
        position: {
          lng: 0,
          lat: 0
        }
      },
      {
        id: '2',
        img: 'projectItem.png',
        name: 'Metroplaza Tower',
        memberNum: 6,
        startDate: 36,
        updatedTime: '2021-12-02',
        position: {
          lng: 0,
          lat: 0
        }
      },
      {
        id: '2',
        img: 'projectItem.png',
        name: 'Metroplaza Tower',
        memberNum: 6,
        startDate: 36,
        updatedTime: '2021-12-02',
        position: {
          lng: 0,
          lat: 0
        }
      },
      {
        id: '2',
        img: 'projectItem.png',
        name: 'Metroplaza Tower',
        memberNum: 6,
        startDate: 36,
        updatedTime: '2021-12-02',
        position: {
          lng: 0,
          lat: 0
        }
      }
      ],
      enterpriseList: [
        {
          title: 'HKATG'
        },
        {
          title: 'IsBim'
        },
        {
          title: 'HK'
        }
      ]
    })
    const viewerRef = ref(null)
    const headerRef = ref(null)
    const mainRef = ref(null)
    const operationRef = ref(null)

    watchEffect(() => {
      if (store.state.breakpoint < store.state.breakpointList.lg) {
        nextTick(() => {
          document.querySelector('#projectListTitle').after(document.querySelector('#mapWrap'))
        })
      } else {
        nextTick(() => {
          document.querySelector('#projectList').after(document.querySelector('#mapWrap'))
        })
      }
    })

    /**
     * 初始化高德地图 函数
     *
     * <pre>
     * center: 地图初始化聚焦经纬度
     * zoom: 聚焦级别
     * pitch: 3D模式，倾斜角度
     * viewMode: 视图模式（3d）
     * mapStyle: 地图自定义样式
     * </pre>
     * @param elementId 地图渲染的节点id
     * @constructor
     */
    // const ProjectMap = (elementId) => new AMap.Map(elementId, {
    //   resizeEnable: true,
    //   center: [114.226031, 22.30693],
    //   zoom: 17,
    //   pitch: 65,
    //   viewMode: '3D',
    //   mapStyle: 'amap://styles/e710b75d9129b8722f4b0ec04d53595e'
    //   // 可选值：en，zh_en, zh_cn
    //   // lang: 'en'
    // })

    // 模拟两个 marker 点
    // const MapMarkers = () => {
    //   const marker1 = new AMap.Marker({ position: new AMap.LngLat(114.226031, 22.30693), title: 'Two Harbour Square' })
    //   const marker2 = new AMap.Marker({ position: new AMap.LngLat(114.20572, 22.259999), title: 'Metroplaza Tower' })

    //   // 自定义icon图标
    //   const icon = new AMap.Icon({
    //     size: new AMap.Size(24, 34),
    //     image: 'http://jarvis-bim-system.oss-cn-shenzhen.aliyuncs.com/share/mark-icon.png',
    //     imageOffset: new AMap.Pixel(0, 0),
    //     imageSize: new AMap.Size(24, 34)
    //   })

    //   marker1.setIcon(icon)
    //   marker2.setIcon(icon)

    //   return [marker1, marker2]
    // }

    onMounted(async () => {
      // 挂载页面时，初始化并渲染地图到 id 为map的节点上
      // ProjectMap('map').add(MapMarkers())
    })

    const toProjectDetail = (item) => {
      router.push('/' + item.id)
    }

    const onTabChange = (key) => {
      state.activeKey = key
    }

    const changeCollection = (item) => {
      event.stopPropagation()
      item.collected = !item.collected
    }

    const handleMenuClick = (e) => {
      console.log(e)
    }

    return {
      ...toRefs(state),
      viewerRef,
      headerRef,
      mainRef,
      operationRef,
      onTabChange,
      toProjectDetail,
      // ProjectMap,
      changeCollection,
      handleMenuClick
      // simpleImage: Empty.PRESENTED_IMAGE_SIMPLE
    }
  }
})
</script>

<style lang="less" scoped>
  .project-collection-con {
    width: 100%;
    height: 100%;
    position: relative;
    padding-top:16px;
    &-lg {
      overflow-y: auto;
    }
    .project-collection{
      width: 516px;
      height: 100%;
      padding: 0px 20px;
      .project-collection-title{
        color: #161717CC;
        font-size: 24px;
        font-weight: 600;
        margin: 0 0px 24px;
      }
      &-lg {
        width:  100%;
        height: auto;
        padding: 0px 10px;
        .project-collection-title{
          padding: 0 0px 8px;
        }
      }

      .project-collection-search{
        color: #161717CC;
        font-size:16px;
        border-bottom: 1px solid #0000001A;
        padding-bottom: 4px;
        .icon{
          background: #00000008;
          color: #161717CC;
          padding: 4px;
          cursor: pointer;
        }
      }
      .project-collection-detail{
        height: calc(100% - 115px);
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 0 !important;
        }
        &:hover {
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 5px !important;
          }
        }
        &-lg {
          height: auto;
        }
        .project-collection-item{
          padding: 16px 0;
          border-bottom: 1px solid #0000001A;
          padding-left: 6px;
          cursor: pointer;
          .collection-icon {
            margin-left: 5px;
            cursor: pointer;
          }
          &:hover {
            .collection-icon {
              display: inline-block;
              &:hover {
                .non-collect-icon {
                  display: none;
                }
                .collect-icon {
                  display: inline-block;
                }
              }
            }
          }
          //  display: flex;
            // flex-direction: column;
            // align-items: center;
          .project-collection-item-img{

          }
          .project-collection-item-info{
            margin-left: 6px;
            // height: 72px;
            flex-direction: column;
            .project-collection-item-info-title{
              color: #161717CC;
              font-size: 16px;
              font-weight: 600;
            }
            .project-collection-item-info-time{
              color: #161717B3;
              font-size: 12px;
            }
          }
        }
      }
    }
    .map{
      height: calc(100% - 16px);
      width: calc(100% - 532px);
      background: lightgrey;
      border-radius: 12px;
      overflow: hidden;
      &-lg {
        width:  100%;
        height: 280px;
        margin-bottom: 8px;
      }
      .map-content {
        /* 隐藏地图底部的 高德logo */
        height: calc(100% + 20px);
        width: 100%;
      }
    }
  }
  .non-f {
    display: none;
  }
</style>
