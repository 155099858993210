<template>
  <div id="map" class="map-content"></div>
</template>
<script>
import { defineComponent, nextTick, onMounted, reactive, toRefs, watchEffect } from 'vue'
// import AMap from 'AMap'
let mainMap
export default defineComponent({
  name: 'MapComponent',
  components: {},
  props: {
    mapConfig: {
      type: Object,
      default: () => {
        //  <pre>
        //  center: 地图初始化聚焦经纬度
        //  zoom: 聚焦级别
        //  pitch: 3D模式，倾斜角度
        //  viewMode: 视图模式（3d）
        //  mapStyle: 地图自定义样式
        //  </pre>
        return {
          resizeEnable: true,
          center: [114.226031, 22.30693],
          zoom: 17,
          pitch: 65,
          viewMode: '3D',
          mapStyle: 'amap://styles/e710b75d9129b8722f4b0ec04d53595e'
          // 可选值：en，zh_en, zh_cn
          // lang: 'en'
        }
      }
    },
    positionList: {
      type: Array,
      default: () => []
    }
  },
  setup (props, context) {
    const state = reactive({

    })
    onMounted(async () => {
      mainMap = ProjectMap('map')
      // MapMarkers()
    })

    const stop = watchEffect(() => {
      if (props.positionList && props.positionList.length) {
        nextTick(() => {
          MapMarkers()
          stop()
        })
      }
    })

    /**
     * 初始化高德地图 函数
     *
     * @param elementId 地图渲染的节点id
     * @constructor
     */
    const ProjectMap = (elementId) => new window.AMap.Map(elementId, props.mapConfig)

    // 模拟两个 marker 点
    const MapMarkers = () => {
      mainMap.clearMap()
      const firstPoi = props.positionList[0]
      mainMap.setCenter(new window.AMap.LngLat(firstPoi.longitude, firstPoi.latitude))
      // 自定义icon图标
      const icon = new window.AMap.Icon({
        size: new window.AMap.Size(24, 34),
        image: 'http://jarvis-bim-system.oss-cn-shenzhen.aliyuncs.com/share/mark-icon.png',
        imageOffset: new window.AMap.Pixel(0, 0),
        imageSize: new window.AMap.Size(24, 34)
      })
      props.positionList.map(i => {
        console.log('11111', i)
        const marker = new window.AMap.Marker({ position: new window.AMap.LngLat(i.longitude, i.latitude) })
        marker.setIcon(icon)
        mainMap.add(marker)
      })
      // const marker1 = new AMap.Marker({ position: new AMap.LngLat(114.226031, 22.30693), title: 'Two Harbour Square' })
      // const marker2 = new AMap.Marker({ position: new AMap.LngLat(114.20572, 22.259999), title: 'Metroplaza Tower' })
      // marker1.setIcon(icon)
      // marker2.setIcon(icon)

      // return [marker1, marker2]
    }

    const getMap = () => {
      return mainMap
    }

    return {
      getMap,
      ...toRefs(state)
    }
  }
})
</script>
<style lang='less' scoped>
.map {
  .map-content {
    /* 隐藏地图底部的 高德logo */
    height: calc(100% + 20px);
    width: 100%;
  }
}
</style>
